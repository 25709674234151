<template>
  <layout :title="$route.name">
    <div class="expande-horizontal wrap">
      <v-list dense>
        <template v-for="ticket in getTickets">
          <v-list-item :key="ticket._id">
            <v-list-item-content>
              <v-list-item-title> {{ ticket.title }} </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list>
    </div>
  </layout>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters(["getTickets"])
  },
  methods: {
    ...mapActions(["listTickets"])
  },
  created() {
    this.listTickets();
  }
};
</script>
